application.register("lib-lazy-video", class extends Stimulus.Controller {
    connect() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    console.log(this.element, 'play')
                    this.element.play()
                } else {
                    console.log(this.element, 'pause')
                    this.element.pause()
                }
            })
        }, {
            rootMargin: "0px",
            threshold: 0.5
        })

        this.observer.observe(this.element)
    }

    disconnect() {
        this.observer.unobserve(this.element)
        this.observer.disconnect()
    }
})
