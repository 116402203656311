loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }

    anchorNext(e) {
        e.preventDefault();

        const nextSibling = e.currentTarget.closest('[class^="comp_"]').nextElementSibling
        if(nextSibling) {
            LibAnchor.animation(nextSibling)
        }
    }

    mute ({ currentTarget }) {
        currentTarget.muted = true
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if (typeof element.dataset.libDialogOpen !== "undefined") {
            let url = element.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")) );
            } else {
                LibDialog.show(document.querySelector(element.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }
    }

    show(e) {
        let element = e.currentTarget;
        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-ns", class extends Stimulus.Controller {
    connect() {
        if (!('scrollBehavior' in document.documentElement.style)) {
            importScript(cdnjs.seamless, () => seamless.polyfill());
        }

        bodyLoaded(() => LibNativeSlider(this.element.querySelector("[data-lib-ns]"), this.element))
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options");
        if(importScript.used.indexOf(cdnjs.tippy) > -1) {
            LibTippy(element, options);
        }
        else {
            bodyLoaded(() => {
                setTimeout(()=> {
                    importScript([cdnjs.popper, cdnjs.tippy],() => {
                        importStyle(cdnjs.tippy_css);
                        LibTippy(element, options);
                    });
                },1500);
            })
        }
    }
});
